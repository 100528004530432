import Hero from 'components/Hero'
import Container from 'components/Container'
import Newsletter from 'components/Newsletter'
import RecentPostsList from 'components/RecentPostsList'
import { PageSEO } from 'components/SEO'
import { allPosts } from 'contentlayer/generated'
import siteMetadata from 'data/siteMetadata'
import { allCoreContent, sortedPublishedPosts } from 'lib/contentlayer'
import { InferGetStaticPropsType } from 'next'
import prisma from 'lib/prisma'
import { ONE_WEEK_IN_SECONDS } from 'lib/utils/date'

const RECENTS_POSTS_TO_DISPLAY = 6
const TOP_VIEWED_POSTS_TO_DISPLAY = 3

export const getStaticProps = async () => {
  const sortedPosts = sortedPublishedPosts(allPosts)
  const topViewedBlogs = await prisma.views.findMany({
    where: { type: 'blog' },
    orderBy: { count: 'desc' },
    take: TOP_VIEWED_POSTS_TO_DISPLAY,
  })

  const topBlogSlugs = topViewedBlogs.map((blog) => `/${blog.type}/${blog.slug}`)
  const topPosts = sortedPosts.filter((post) => topBlogSlugs.includes(post.slug))

  const recentPosts = sortedPosts
    .filter((post) => !topBlogSlugs.includes(post.slug))
    .slice(0, RECENTS_POSTS_TO_DISPLAY)

  return {
    props: { posts: allCoreContent(recentPosts), topPosts: allCoreContent(topPosts) },
    revalidate: ONE_WEEK_IN_SECONDS,
  }
}

export default function Home({ posts, topPosts }: InferGetStaticPropsType<typeof getStaticProps>) {
  return (
    <Container showCircles>
      <PageSEO title={siteMetadata.title} description={siteMetadata.description} />
      <div className="space-y-10">
        <Hero />
        <hr className="hr" />
        <RecentPostsList posts={posts} topPosts={topPosts} />
        {siteMetadata.newsletter.provider !== '' && (
          <div className="flex items-center justify-center pt-4">
            <Newsletter />
          </div>
        )}
      </div>
    </Container>
  )
}
