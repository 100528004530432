import React, { FunctionComponent } from 'react'
import GradientText from './text/GradientText'
import Image from './Image'
import siteMetadata from 'data/siteMetadata'
import Button from './Button'
import Link from './Link'

const Hero: FunctionComponent = (): JSX.Element => {
  return (
    <div>
      <div className="flex flex-col min-h-[70vh] justify-center">
        <div className="flex lg:flex-row flex-col-reverse gap-4">
          {/* Left section */}
          <div className="flex flex-col justify-between gap-8">
            <div className="space-y-6">
              <h1 className="text-4xl leading-normal lg:leading-relaxed lg:text-5xl font-bold">
                Hey there,{' '}
                <span
                  role="img"
                  aria-label="wave hi"
                  className="animate-wave inline-block origin-[70%_70%]"
                >
                  👋
                </span>{' '}
                I'm <GradientText>Akash Rajpurohit.</GradientText>
              </h1>
              <p className="text-lg lg:text-xl dark:text-gray-300 mt-4">
                Software Engineer based out of Mumbai. I like to make digital experiences easier and
                simpler for people.
              </p>
            </div>
            <div className="flex lg:flex-row gap-4">
              <Link href={siteMetadata.pages.blogs}>
                <Button className="w-full rounded-lg px-6 py-4">Check blog</Button>
              </Link>
              <Link href={siteMetadata.pages.about}>
                <Button className="w-full rounded-lg px-6 py-4" variant="outline">
                  About me
                </Button>
              </Link>
            </div>
          </div>

          {/* Right section */}
          <div>
            <div className="hidden lg:block mx-10 border-2 border-amber-500 rounded-2xl p-4">
              <Image
                alt={siteMetadata.author}
                src={siteMetadata.image_large}
                width="534"
                height="712"
                className="col-span-1"
              />
            </div>

            <div className="lg:hidden inline-block">
              <Image
                alt={siteMetadata.author}
                src={siteMetadata.image_large}
                width="128"
                height="128"
                className="col-span-1 object-cover object-top rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero
