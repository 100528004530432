/* eslint-disable @next/next/no-img-element */
import { classNames } from 'lib/utils/classnames'
import React, { FunctionComponent } from 'react'

const getRandomGradientClasses = () => {
  const gradients = [
    'bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500',
    'bg-gradient-to-r from-red-200 via-red-300 to-yellow-200',
    'bg-gradient-to-r from-yellow-200 via-green-200 to-green-500',
    'bg-gradient-to-r from-green-300 via-blue-500 to-purple-600',
    'bg-gradient-to-r from-pink-300 via-purple-300 to-indigo-400',
    'bg-gradient-to-r from-green-200 via-green-300 to-blue-500',
    'bg-gradient-to-r from-yellow-200 via-green-200 to-green-300',
    'bg-gradient-to-b from-orange-500 to-yellow-300',
    'bg-gradient-to-b from-sky-400 to-sky-200',
    'bg-gradient-to-r from-blue-400 to-emerald-400',
    'bg-gradient-to-tr from-yellow-400 via-gray-50 to-teal-300',
    'bg-gradient-to-r from-rose-300 to-rose-500',
    'bg-gradient-to-r from-emerald-500 to-lime-600',
    'bg-gradient-to-r from-fuchsia-600 to-pink-600',
    'bg-gradient-to-r from-rose-500 via-red-400 to-red-500',
    'bg-gradient-to-r from-sky-400 to-cyan-300',
    'bg-gradient-to-r from-cyan-200 to-cyan-400',
    'bg-gradient-to-r from-teal-200 to-lime-200',
  ]
  return gradients[Math.floor(Math.random() * gradients.length)]
}

const getRandomAnimationDelay = () => {
  const randomNum = Math.floor(Math.random() * 10)
  if (randomNum <= 3) return ''
  if (randomNum <= 6) return 'animation-delay-2000'
  else return 'animation-delay-4000'
}

const Card: FunctionComponent = ({ children }): JSX.Element => {
  return (
    <div className="relative h-full group">
      {/* Gradient Border */}
      <div
        className={classNames(
          'absolute -inset-0.5 rounded-lg blur opacity-75 group-hover:opacity-100 group-hover:-inset-1 transition-all duration-1000 group-hover:duration-300 animate-tilt',
          getRandomGradientClasses(),
          getRandomAnimationDelay()
        )}
      ></div>
      {/* Actual Card */}
      <div className="relative flex flex-col rounded-lg shadow-lg h-full overflow-hidden transition-all duration-300">
        {children}
      </div>
    </div>
  )
}

export default Card
