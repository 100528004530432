import { classNames } from 'lib/utils/classnames'
import React, { FunctionComponent } from 'react'

interface IGradientTextProps {
  className?: string
}

const GradientText: FunctionComponent<IGradientTextProps> = ({
  children,
  className = '',
  ...rest
}): JSX.Element => {
  const elementClassName = classNames(
    'bg-clip-text text-transparent bg-gradient-to-r font-extrabold from-pink-600 to-sky-600 dark:from-pink-400 dark:to-sky-400 selection:text-gray-800 dark:selection:text-gray-200',
    className
  )
  return (
    <span className={elementClassName} {...rest}>
      {children}
    </span>
  )
}

export default GradientText
