/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Link from 'components/Link'
import { Post } from 'contentlayer/generated'
import { CoreContent } from 'lib/contentlayer'
import { trackEvent } from 'lib/utils/analytics'
import React, { FunctionComponent } from 'react'
import BlogCard from './cards/BlogCard'
import Button from './Button'

interface IRecentPostsListProps {
  posts: CoreContent<Post>[]
  topPosts: CoreContent<Post>[]
}

const RecentPostsList: FunctionComponent<IRecentPostsListProps> = ({
  posts,
  topPosts,
}): JSX.Element => {
  const onRecentBlogPostClick = (slug: string) => {
    trackEvent({ name: 'Recent Posts', data: { slug } })
  }

  const onTopBlogPostClick = (slug: string) => {
    trackEvent({ name: 'Top Posts', data: { slug } })
  }

  return (
    <div>
      <div>
        <div className="pt-6">
          <h1 className="text-3xl font-medium leading-9 tracking-tight text-gray-900 dark:text-gray-100 sm:leading-10 md:text-4xl md:leading-14">
            Documenting my journey through writings.
          </h1>
        </div>
        <div className="py-4 space-y-6">
          <p className="text-lg text-gray-700 dark:text-gray-300">
            Top viewed posts by the community
          </p>
          <ul className="mx-auto grid gap-10 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
            {topPosts.map((frontMatter, idx) => {
              const { slug } = frontMatter
              return (
                <li
                  key={slug}
                  data-aos="fade-up"
                  data-aos-delay={idx > 2 ? 250 : (idx + 1) * 100}
                  onClick={() => onTopBlogPostClick(slug)}
                >
                  <BlogCard frontMatter={frontMatter} />
                </li>
              )
            })}
          </ul>
        </div>
        <hr className="hr" />
        <div className="py-4 space-y-6">
          <p className="text-lg text-gray-700 dark:text-gray-300">
            Check out some recently published articles
          </p>
          <ul className="mx-auto grid gap-10 md:grid-cols-2 lg:grid-cols-3 lg:max-w-none">
            {posts.map((frontMatter, idx) => {
              const { slug } = frontMatter
              return (
                <li
                  key={slug}
                  data-aos="fade-up"
                  data-aos-delay={idx > 2 ? 250 : (idx + 1) * 100}
                  onClick={() => onRecentBlogPostClick(slug)}
                >
                  <BlogCard frontMatter={frontMatter} />
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="mt-8 flex justify-end text-base font-medium leading-6">
        <Link href="/blogs" aria-label="all posts">
          <Button variant="ghost">All Posts &rarr;</Button>
        </Link>
      </div>
    </div>
  )
}

export default RecentPostsList
