/* eslint-disable @next/next/no-img-element */
import { Post } from 'contentlayer/generated'
import { CoreContent } from 'lib/contentlayer'
import { formatDate } from 'lib/utils/date'
import React, { FunctionComponent } from 'react'
import Tag from '../Tag'
import Card from './Card'

interface IBlogCardProps {
  frontMatter: CoreContent<Post>
}

const BlogCard: FunctionComponent<IBlogCardProps> = ({
  frontMatter: { date, slug, tags, readingTime, summary, title },
}): JSX.Element => {
  return (
    <Card>
      <a href={`https://akashrajpurohit.com${slug}/?ref=v3`} className="h-full">
        <div
          className="flex-1 bg-gray-100 dark:bg-gray-900 rounded-md p-6 flex flex-col justify-between h-full"
          key={title}
          aria-label={`Read "${title}"`}
        >
          <div className="flex-1">
            <p className="text-sm font-medium whitespace-pre-line flex flex-wrap">
              {tags.map((tag) => (
                <Tag key={tag} text={tag} />
              ))}
            </p>
            <p className="text-2xl mt-3 font-semibold text-gray-900 dark:text-white">{title}</p>
            <p className="mt-4 line-clamp-4 text-base text-gray-700 dark:text-gray-400">
              {summary}
            </p>
          </div>
          <div className="mt-6 flex items-center">
            <div className="flex space-x-1 text-sm text-gray-600 dark:text-gray-400">
              <time dateTime={date}>{formatDate(date)}</time>
              <span aria-hidden="true">&middot;</span>
              <span>{readingTime.text}</span>
            </div>
          </div>
        </div>
      </a>
    </Card>
  )
}

export default BlogCard
