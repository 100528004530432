import { Author } from 'contentlayer/generated'
import siteMetadata from 'data/siteMetadata'
import Head from 'next/head'
import { useRouter } from 'next/router'

interface CommonSEOProps {
  title: string
  description: string
  ogType: string
  imageUrl: string
}

const CommonSEO = ({ title, description, ogType, imageUrl }: CommonSEOProps) => {
  const router = useRouter()
  return (
    <Head>
      <title>{title}</title>
      <meta name="robots" content="follow, index" />
      <meta name="description" content={description} />
      <meta property="og:url" content={`${siteMetadata.siteUrl}${router.asPath}`} />
      <meta property="og:type" content={ogType} />
      <meta property="og:site_name" content={siteMetadata.title} />
      <meta property="og:description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={siteMetadata.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Head>
  )
}

interface PageSEOProps {
  title: string
  description: string
}

export const PageSEO = ({ title, description }: PageSEOProps) => {
  const imageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  return <CommonSEO title={title} description={description} ogType="website" imageUrl={imageUrl} />
}

export const TagSEO = ({ title, description }: PageSEOProps) => {
  const imageUrl = siteMetadata.siteUrl + siteMetadata.socialBanner
  const router = useRouter()
  return (
    <>
      <CommonSEO title={title} description={description} ogType="website" imageUrl={imageUrl} />
      <Head>
        <link
          rel="alternate"
          type="application/rss+xml"
          title={`${description} - RSS feed`}
          href={`${siteMetadata.siteUrl}${router.asPath}/rss.xml`}
        />
      </Head>
    </>
  )
}

interface ArticleSeoProps {
  url: string
  title: string
  summary: string
  coverUrl: string
  readingTime: string
  date?: string
  lastmod?: string
  authorDetails?: Author[]
}

export const ArticleSEO = ({
  authorDetails,
  title,
  summary,
  coverUrl,
  date,
  lastmod,
  url,
  readingTime,
}: ArticleSeoProps) => {
  const router = useRouter()
  const publishedAt = new Date(date).toISOString()
  const modifiedAt = new Date(lastmod || date).toISOString()

  const featuredImages = [
    {
      '@type': 'ImageObject',
      url: coverUrl,
    },
  ]

  let authorList
  if (authorDetails) {
    authorList = authorDetails.map((author) => {
      return {
        '@type': 'Person',
        name: author.name,
      }
    })
  } else {
    authorList = {
      '@type': 'Person',
      name: siteMetadata.author,
    }
  }

  const structuredData = {
    '@context': 'https://schema.org',
    '@type': 'Article',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': url,
    },
    headline: title,
    image: featuredImages,
    datePublished: publishedAt,
    dateModified: modifiedAt,
    author: authorList,
    publisher: {
      '@type': 'Organization',
      name: siteMetadata.author,
      logo: {
        '@type': 'ImageObject',
        url: `${siteMetadata.siteUrl}${siteMetadata.siteLogo}`,
      },
    },
    description: summary,
  }

  return (
    <>
      <CommonSEO title={title} description={summary} ogType="article" imageUrl={coverUrl} />
      <Head>
        {date && <meta property="article:published_time" content={publishedAt} />}
        {lastmod && <meta property="article:modified_time" content={modifiedAt} />}
        <meta name="twitter:label1" content="Written by" />
        <meta name="twitter:data1" content={siteMetadata.author} />
        <meta name="twitter:label2" content="Est. reading time" />
        <meta name="twitter:data2" content={readingTime} />
        <link rel="canonical" href={`${siteMetadata.siteUrl}${router.asPath}`} />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(structuredData, null, 2),
          }}
        />
      </Head>
    </>
  )
}
