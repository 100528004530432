import siteMetadata from 'data/siteMetadata'

export const formatDate = (date: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }
  const now = new Date(date).toLocaleDateString(siteMetadata.locale, options)

  return now
}

export const ONE_DAY_IN_SECONDS = 60 * 60 * 24
export const ONE_WEEK_IN_SECONDS = ONE_DAY_IN_SECONDS * 7
